const RECRUITEE_BACKEND = {
  protocol: 'https',
  referralsProtocol: 'https',
  domain: 'api.rc.recruitee.dev',
  partialDomain: 'rc.recruitee.dev',
  referralsPartialDomain: 'referrals.rc.recruitee.dev',
  apiUrl: 'https://api.rc.recruitee.dev',
  authUrl: 'https://auth.rc.recruitee.dev',
  appUrl: 'https://rc.recruitee.dev',
  webAppUrl: 'https://app.rc.recruitee.dev',
  mobileAppUrl: 'https://mobile.rc.recruitee.dev',
  wsUrl: 'wss://api.rc.recruitee.dev/socket',
  widgetUrl: 'https://jobs-widget.recruiteecdn.com/widget.js',
  widgetApiEndpoint: 'https://career.rc.recruitee.dev/api/c',
  videoAppUrl: 'https://video.rc.recruitee.dev',
  CDNHost: 'https://app.rc.recruitee.dev',
  pdfViewerDirectory: 'pdfjs-patched-rc',
  integrationsUrl: 'https://integrations.rc.recruitee.dev',
  tellentApiUrl: 'https://api.rc.tellent.com',
  tellentAuthUrl: 'https://auth.rc.tellent.com',
  tellentAppUrl: 'https://admin.rc.tellent.com'
};

/**
 * Generated bundle index. Do not edit.
 */

export { RECRUITEE_BACKEND };
