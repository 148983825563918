import { RECRUITEE_BACKEND } from '@recruitee/environment/rc';

import { ReferralsEnvironment } from './interface';

export const environment: ReferralsEnvironment = {
  environmentName: 'rc',
  angularProdMode: true,
  analytics: {
    isEnabled: true,
    key: 'DlaokckWxNBiyBoQUTZJGRRF4MeRanyf',
    requiredFeatureFlag: true,
  },
  i18n: {
    phraseAppProjectId: '33b93fa8c87183f3257609a570160087',
    editorEnabled: true,
  },
  hmr: false,
  protocol: RECRUITEE_BACKEND.protocol,
  domain: RECRUITEE_BACKEND.domain,
  authUrl: RECRUITEE_BACKEND.authUrl,
  apiUrl: RECRUITEE_BACKEND.apiUrl,
  appUrl: RECRUITEE_BACKEND.appUrl,
  webAppUrl: RECRUITEE_BACKEND.webAppUrl,
  landingPageUrl: RECRUITEE_BACKEND.appUrl,
  recaptchaSiteKey: '6LdznlAUAAAAAGaYOdwE_id-rzKrOvsc-a-LHRGl',
  websocketUrl: 'wss://api.rc.recruitee.dev/referral_portal_socket',
  resolveSubdomainFromHost: true,
  sentry_dsn: 'https://d390442d73ea42abaf64cc21c87f82f6@o18167.ingest.sentry.io/4504254923276288',
};
